:root {
  --bg: white;
  --fg: black;
  --error-fg: red;
  --disabled-bg: #eee;
  --header-bg: #cdf;
  --status-bg: #eee;
  --border: #aaa;
  --border-next-assignment: #00f;

  /* font shadows inspired from typingclub.com */
  --assignment-n-fg: #00f8;
  --assignment-n-shadow:
    0.01em 0.02em 0.15em #fff,
    0 0 0 #008,
    0.01em 0.02em 0.15em #fff;
  --assignment-n-dis-fg: #8888;
  --assignment-n-dis-shadow:
    0.01em 0.02em 0.15em #fff,
    0 0 0 #000,
    0.01em 0.02em 0.15em #fff;
  --assignment-bg: #cdf;
  --assignment-level-fg: #00f;
  --assignment-challenge-bg: #ffa;
  --assignment-tick-correct: #0808;
  --assignment-tick-correct-shadow:
    0.01em 0.02em 0.15em #fff,
    0 0 0 #080,
    0.01em 0.02em 0.15em #fff;
  --assignment-tick-failed: gray;
}

html,
body {
  background-color: var(--bg);
  color: var(--fg);
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}

#app {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#app-header {
  background: var(--header-bg);
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
}

#app-header > div {
  position: relative;
  padding: 1em 0;
}

main {
  margin: 1em auto;
  padding: 0 1em;
  width: 100%;
  max-width: 50em;
}

footer {
  background: var(--header-bg);
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  margin-top: auto;
  display: flex;
}

footer > * {
  margin: 0;
}

footer > *:not(:last-child)::after {
  content: '   |   ';
}

#app > div.working > * {
  opacity: 0.5;
  pointer-events: none;
}

#app > div.working::after {
  content: ' ';
  background-image: url(/images/spinner.svg);
  background-position: center;
  background-size: 5em;
  background-repeat: no-repeat;
  animation: spin 1s steps(12) infinite;
  display: block;
  width: 5em;
  height: 5em;
  position: absolute;
  top: calc(50% - 2.5em);
  left: calc(50% - 2.5em);
}

@keyframes spin {
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
}
