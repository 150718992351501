.level-indicator.won .level {
  position: relative;
  z-index: 0;
  font-weight: bold;
}

.level-indicator.won .level::after {
  position: absolute;
  left: -20%;
  top: -0.5em;
  content: '⭐️';
  animation: 1s ease-out infinite level-indicator-pulse;
  z-index: -1;
  display: block;
}

.level-indicator .star.won::before {
  position: absolute;
  content: '⭐️';
  animation: 1s ease-out infinite solve-assignment-pulse;
}

@keyframes level-indicator-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.level-indicator .stars {
  border: solid thin var(--border);
  border-radius: 0.5em;
  padding: 0.3em;
  margin-left: 1em;
  line-height: 1em;
}

.level-indicator .star::after {
  content: '⭐️';
}

.level-indicator .star.needed::after {
  filter: grayscale(0.8);
  opacity: 0.5;
}
