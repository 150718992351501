#solve-assignment {
  position: relative;
}

#solve-assignment .equation,
#solve-assignment .answer {
  font-family: "Times New Roman", serif;
  font-style: italic;
  font-size: 150%;
}

#solve-assignment textarea.equation,
#solve-assignment pre.equation {
  display: block;
  border: thin solid var(--border);
  outline: none;
  box-sizing: border-box;
  min-height: 6em;
  margin-top: 0.5em;
  margin-bottom: 0;
  width: 100%;
  white-space: pre-line;
  overflow-wrap: break-word;
}

#solve-assignment pre.equation {
  padding: 0.2rem;
  background: var(--disabled-bg);
}

#solve-assignment .answer {
  line-height: 2em;
  height: 2em;
  display: block;
  white-space: pre;
}

#solve-assignment .answer input {
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  padding: 0.3em;
  line-height: 2em;
  height: 2em;
  box-sizing: border-box;
}

#solve-assignment button {
  font-size: 1em;
  padding: 1em;
}

#solve-assignment .buttons {
  display: flex;
}

#solve-assignment .buttons > * {
  margin-right: 1em;
  margin-bottom: 1em;
}

#solve-assignment .buttons > #show-answers {
  margin-left: auto;
}

#solve-assignment .buttons > :last-child {
  margin-right: 0;
}

#solve-assignment .equations-text {
  margin: 1em 7em 1em 0;
}

#solve-assignment .info {
  width: 6em;
  height: 4em;
  padding: 0.75em 0;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 1em;
  z-index: 0;
  text-align: center;
}

#solve-assignment .assignment-icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: auto;
  z-index: -1;
}

#solve-assignment .n {
  font-family: sans-serif;
  font-size: 2.5em;
  line-height: 1em;
  display: block;
  color: var(--assignment-n-fg);
  text-shadow: var(--assignment-n-shadow);
  font-weight: bold;
}

#solve-assignment.challenge .info::before {
  content: '⭐️';
  font-size: 1em;
  position: absolute;
  top: 0;
  right: 0;
}

#solve-assignment.challenge.won .info::before {
  animation: 1s ease-out infinite solve-assignment-pulse;
}

#solve-assignment + footer .level-indicator .star.won::after {
  opacity: 0;
}

@keyframes solve-assignment-pulse {
  0% { transform: scale(1); }
  90% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

#solve-assignment .answers {
  margin: 1rem 0;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#solve-assignment .answers .answer {
  margin-bottom: 1rem;
}

#solve-assignment .answers .answer:last-child {
  margin-bottom: 0;
}

#solve-assignment .answers .correctness {
  font-size: 1.5em;
  margin-left: 2em;
  align-self: center;
}

#solve-assignment.transitioning {
  transition: opacity 0.1s;
  opacity: 0;
}
