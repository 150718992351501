#overview .assignments {
  display: flex;
  flex-direction: row;
}

#overview .assignment {
  position: relative;
  width: 20%;
  min-height: 4em;
  z-index: 0;
  padding: 1em;
  border: solid thin var(--border);
  border-radius: 0.5em;
  text-align: center;
  text-decoration: none;
  background-color: var(--assignment-bg);
}

#overview .assignment.challenge {
  background-color: var(--assignment-challenge-bg);
}

#overview .assignment:not(.disabled):not(.done) {
  border: solid thick var(--border-next-assignment);
}

#overview .assignment.disabled {
  cursor: default;
  filter: grayscale(0.8);
}

#overview .assignment:not(.disabled):hover {
  box-shadow: 0.3em 0.3em 1em var(--border);
}

#overview .assignments > .assignment:not(:first-child) {
  margin-left: 1em;
}

#overview .assignment .icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-height: 100%;
  max-width: 100%;
  padding: 1em;
  box-sizing: border-box;
  opacity: 0.5;
  margin: auto;
  z-index: -1;
}

#overview .assignment .n {
  font-family: sans-serif;
  font-size: 4em;
  color: var(--assignment-n-fg);
  text-shadow: var(--assignment-n-shadow);
  font-weight: bold;
}

#overview .assignment .level {
  font-size: 0.9em;
  position: absolute;
  bottom: 0.2em;
  right: 0.3em;
  color: var(--assignment-level-fg);
}

#overview .assignment.done .n {
  font-size: 2em;
  position: absolute;
  left: 0.4em;
  top: 0.3em;
}

#overview .assignment.done::before {
  font-size: 4em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#overview .assignment.done.correctly::before {
  content: '✓';
  color: var(--assignment-tick-correct);
  text-shadow: var(--assignment-tick-correct-shadow);
}

#overview .assignment.done.asked::before {
  color: var(--assignment-tick-failed);
  content: '—';
}

#overview .assignment.disabled .n {
  color: var(--assignment-n-dis-fg);
  text-shadow: var(--assignment-n-dis-shadow);
}

#overview .assignment.challenge:not(.made-challenge)::after {
  content: '⭐️';
  font-size: 2em;
  position: absolute;
  top: 0.1em;
  right: 0.2em;
}

#overview .assignment .make-challenge {
  font-size: 1.5em;
  position: absolute;
  top: 0.1em;
  right: 0.2em;
}

#overview .assignment .make-challenge:hover {
  transform: scale(1.5);
}

#overview .assignment:not(.challenge):not(.made-challenge):not(:hover) .make-challenge {
  filter: grayscale(1);
}

#overview .assignment.challenge.made-challenge .make-challenge:hover {
  filter: grayscale(1);
}
